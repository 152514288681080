





































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import PayrollTextInput from '../../../components/PayrollTextInput/index.vue'
import CheckBox from '../../../components/CheckBox/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import { LocalStorage } from '@/app/infrastructures/misc'
import { Tier } from '@/domain/entities/Payroll'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface Dropdown {
  value: string
  label: string
}

interface IBonusFee {
  bonusRecipient?: Dropdown[]
  captainBonus?: Dropdown[]
  captainBonusFee?: number
  captainBonusOptions?: string[]
  insurance: boolean
  percentageBonus: boolean
  percentageBonusData: IPercentageData[]
}

interface IBonusData {
  minStt: number
  bonus: number
}

interface IPercentageData {
  adjustment: number
  bonusMaxLimit: number
  percentage: number
}

interface PeriodRule {
  basicFee: {
    calculationMethod: Array<string>
    productType: Array<string>
    multiplyType: Array<string>
    rules: Array<Record<string, string | Array<Tier>>>
  }
  basicFeeId: number
  bonusFee: {
    bonusRecipient: Array<string>
    captainBonus: Array<string>
    captainBonusFee: number
    captainBonusOptions: Array<string>
    insurance: boolean
    percentageBonus: boolean
    percentageBonusData: Array<IPercentageData>
  }
}

@Component({
  components: {
    TextInput,
    Button,
    MultiselectV2,
    Loading,
    PayrollTextInput,
    DropdownSelect,
    CheckBox,
  },
})
export default class BonusMechanism extends Vue {
  controller = controller
  routeController = routeController
  currentPeriod = ''
  isBonusPickUpAll3LCActive = false
  isBonusCaptainAll3LCActive = false

  originOptions: Dropdown[] = []

  form: IBonusFee = {
    bonusRecipient: [],
    captainBonus: [],
    captainBonusFee: 0,
    captainBonusOptions: [],
    insurance: false,
    percentageBonus: false,
    percentageBonusData: [],
  }

  created(): void {
    this.currentPeriod = LocalStorage.getLocalStorage('selectedPeriod')
    this.fetchData()
  }

  private fetchData(): void {
    controller.getDetail({
      id: this.$route.params.periodId,
      page: parseInt(<string>this.$route.params.page),
    })
  }

  private mapOrigin(name: 'bonusRecipient' | 'captainBonus'): Dropdown[] {
    const res: Dropdown[] = []

    if (
      controller.dataPeriodRule.bonusFee &&
      controller.dataPeriodRule.bonusFee[name]
    ) {
      controller.dataPeriodRule.bonusFee[name]?.forEach(city => {
        const cityOption = this.originOptions.find(
          origin => origin.value === city
        )
        if (cityOption) {
          res.push(cityOption)
        }
      })

      this.form[name] = res
    }

    return res
  }

  @Watch('controller.dataPeriodRule')
  setBonusFee(data: PeriodRule): void {
    const bonusFee = data.bonusFee

    const res: IBonusFee = {
      bonusRecipient: [],
      captainBonus: [],
      captainBonusFee: bonusFee?.captainBonusFee,
      captainBonusOptions: bonusFee?.captainBonusOptions,
      insurance: bonusFee?.insurance || false,
      percentageBonus: bonusFee?.percentageBonus || false,
      percentageBonusData:
        <IPercentageData[]>bonusFee?.percentageBonusData || [],
    }

    if (
      bonusFee?.bonusRecipient?.length === 1 &&
      bonusFee.bonusRecipient[0] === '3LC'
    ) {
      this.isBonusPickUpAll3LCActive = true
    }

    if (
      bonusFee?.captainBonus?.length === 1 &&
      bonusFee.captainBonus[0] === '3LC'
    ) {
      this.isBonusCaptainAll3LCActive = true
    }

    this.form = res
    this.routeController.getLetterCodes()
  }

  @Watch('routeController.letterCodes')
  onLetterCodesChanged(data: LetterCode[]): void {
    this.originOptions = data.map(route => {
      return {
        label: <string>route.lc,
        value: <string>route.lc,
      }
    })
    this.mapOrigin('bonusRecipient')
    this.mapOrigin('captainBonus')
  }
}
